import * as React from "react";
const PeopleIcon = () => (
  <svg className="w-full h-full" viewBox="0 0 14 11" fill="none">
    <path
      fill="#fff"
      d="M6.125 11s-.875 0-.875-.917c0-.916.875-3.666 4.375-3.666S14 9.167 14 10.083c0 .917-.875.917-.875.917h-7Zm3.5-5.5c.696 0 1.364-.29 1.856-.805a2.818 2.818 0 0 0 .769-1.945c0-.73-.277-1.429-.769-1.945A2.566 2.566 0 0 0 9.625 0c-.696 0-1.364.29-1.856.805A2.818 2.818 0 0 0 7 2.75c0 .73.277 1.429.769 1.945.492.515 1.16.805 1.856.805ZM4.564 11c-.13-.286-.194-.6-.189-.917 0-1.242.595-2.52 1.694-3.41a5.302 5.302 0 0 0-1.694-.256C.875 6.417 0 9.167 0 10.083 0 11 .875 11 .875 11h3.689Zm-.627-5.5c.58 0 1.137-.241 1.547-.671.41-.43.641-1.013.641-1.62 0-.608-.23-1.191-.64-1.621A2.138 2.138 0 0 0 3.936.917c-.58 0-1.136.241-1.546.67-.41.43-.641 1.014-.641 1.621 0 .608.23 1.191.64 1.62.41.43.967.672 1.547.672Z"
    />
  </svg>
);
export default PeopleIcon;
